<template>
    <v-card>
        <v-card-title>
            <v-row>
                <v-col v-show="pessoa.id" class="text-right">
                    {{ pessoa.username }} # {{ pessoa.id || '' }}
                </v-col>
            </v-row>
        </v-card-title>
        <v-form ref="form_permissions">
            <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-divider class="mb-3"></v-divider>
                </v-col>
                <v-col cols="12">Status</v-col>
            </v-row>
            <v-row dense>
                <v-col>
                    <v-checkbox 
                        v-model="pessoa.is_active"
                        label="Ativo"
                        @change="upoload_permissoes()"
                        >
                    </v-checkbox>
                </v-col>
                <v-col>
                    <v-checkbox 
                        v-model="pessoa.is_staff"
                        label="Admin"
                        @change="upoload_permissoes()"
                        >
                    </v-checkbox>
                </v-col>
                <v-col>
                    <v-checkbox 
                        v-model="pessoa.is_superuser"
                        label="Super usuário"
                        @change="upoload_permissoes()"
                        >
                    </v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-divider class="mb-3"></v-divider>
                </v-col>
                <v-col cols="12">Grupos</v-col>
            </v-row>
            <v-row>
                <v-col cols="6"><!-- GRUPOS A SEREM ADDCIONADOS -->
                    <v-card outlined class="ma-1">
                        <v-card-title>Add</v-card-title>
                        <v-data-table
                            :items="grupos"
                            :search="search"
                            :headers="headers"
                        >
                            <template
                                v-slot:top
                            >
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="search"
                                            label="Pesquisar"
                                            class="px-1"
                                            outlined
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </template>
                            <template 
                                v-slot:[`item.action`]="{item}"
                            >
                                <v-btn icon @click="add_grupo(item.pk)">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <v-col cols="6"><!-- GRUPOS ADDCIONADOS -->
                    <v-card outlined class="ma-1">
                        <v-card-title>Remover</v-card-title>
                        <v-data-table
                            :items="pessoa.groups"
                            :search="search2"
                            :headers="headers"
                            :loading="loading"
                        >
                            <template
                                v-slot:top
                            >
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="search2"
                                            label="Pesquisar"
                                            class="px-1"
                                            outlined
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </template>
                            <template 
                                v-slot:[`item.action`]="{item}"
                            >
                                <v-btn icon @click="remove_grupo(item.pk)">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
               <!-- 
                   {{pessoa}}
               --> 
            <v-row>
                <v-col cols="12">
                    <v-divider class="mb-3"></v-divider>
                </v-col>
                <v-col cols="12">Permissões</v-col>
            </v-row>
            
            <v-row>
                <v-col cols="6">
                    <v-card outlined class="ma-1">
                        <v-card-title>Add</v-card-title>
                        <v-data-table
                            :items="permissions"
                            :search="search3"
                            :headers="headers"
                        >
                            <template
                                v-slot:top
                            >
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="search3"
                                            label="Pesquisar"
                                            class="px-1"
                                            outlined
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </template>
                            <template 
                                v-slot:[`item.action`]="{item}"
                            >
                                <v-btn icon @click="add_permissao(item.id)">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card outlined class="ma-1">
                        <v-card-title>Remover</v-card-title>
                        <v-data-table
                            :items="pessoa.user_permissions"
                            :search="search4"
                            :headers="headers"
                        >
                            <template
                                v-slot:top
                            >
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="search4"
                                            label="Pesquisar"
                                            class="px-1"
                                            outlined
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </template>
                            <template 
                                v-slot:[`item.action`]="{item}"
                            >
                                <v-btn icon @click="remove_permissions(item.id)">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        
        
            </v-card-text>
        </v-form>

    </v-card>
</template>
<script>
import api from '@/http'
import { mapGetters } from 'vuex'
    export default{
        name:'Permissoes',
        data:()=>({
            search:'',
            search2:'',
            search3:'',
            search4:'',
            loading:false,
            headers:[
                { align: 'start',sortable: true, value: 'pk',},
                { align: 'end',sortable: true, value: 'name',},
                { align: 'end', value: 'action',},
            ],
            grupos:[],
            permissions:[],
        }),
        methods:{
            async get_grupos(){
                const res = await api('contas/grupos/')
                this.grupos =res.data
            },
            async get_permissions(){
                const res = await api('contas/permissions/')
                this.permissions =res.data
            },
            upoload_grupos_permissoes(val){

                let pksg = this.pessoa.groups.map((e)=>{
                    return e.pk
                }) 
                let pksp = this.pessoa.user_permissions.map((e)=>{
                    console.log(e.id)
                    return e.id
                }) 
                var url = val=='grupos' ? 'contas/groups/' : 'contas/groups/'
                var obj = val=='grupos' ? {groups:pksg}:{user_permissions:pksp}
                api.put(`${url}${this.pessoa.id}/`,obj)
                .finally(()=>{
                    this.loading = false
                })
            },
            upoload_permissoes(){
                this.loading = true;
                const {is_active, is_staff, is_superuser} = this.pessoa 
                let objeto  = {is_active, is_staff, is_superuser}
                api.put(`contas/rud-permissoes/${this.pessoa.id}/`, objeto)
                .finally(()=>{
                    this.loading = false
                })
            },
            add_grupo(val){ 
                var e = this.grupos
                for(var i=0; i< e.length; i++){
                    if(e[i].pk!==undefined && e[i].pk==val){
                        this.pessoa.groups.push(e[i])
                        this.grupos.splice(i,1)
                    }
                }
                this.upoload_grupos_permissoes('grupos')
            },
            remove_grupo(val){ 
                var e = this.pessoa.groups
                for(var i=0; i< e.length; i++){
                    if(e[i].pk!==undefined && e[i].pk==val){
                        this.grupos.push(e[i])
                        this.pessoa.groups.splice(i,1)
                    }
                }
                this.upoload_grupos_permissoes('grupos')
            },
            add_permissao(val){ 
                var e = this.permissions
                for(var i=0; i< e.length; i++){
                    if(e[i].id!==undefined && e[i].id==val){
                        this.pessoa.user_permissions.push(e[i])
                        this.permissions.splice(i,1)
                    }
                }
                this.upoload_grupos_permissoes('')
            },
            remove_permissions(val){ 
                var e = this.pessoa.user_permissions
                for(var i=0; i< e.length; i++){
                    if(e[i].id!==undefined && e[i].id==val){
                        this.permissions.push(e[i])
                        this.pessoa.user_permissions.splice(i,1)
                    }
                }
                this.upoload_grupos_permissoes('')
            },
        },
        computed:{
            ...mapGetters(['pessoa'])
        },
        watch:{
            
            grupos:{
                handler(val){
                    Object.keys(val).forEach((key) => {
                        var e = this.pessoa.groups
                        for(var i=0; i<e.length; i++){
                            
                            if(val[key]!==undefined){
                                if( val[key].pk==e[i].pk){
                                    val.splice(val.indexOf(val[key]),1)
                                }
                            }
                        }
                    });
                },deep:true
            }
            
        },
        mounted(){
            this.get_grupos()
            this.get_permissions()
            
        }
    }
</script>